
.project-img {
    position: relative;
    padding: 15px;
  }
  
  .project-img::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 150px;
    top: 0;
    left: 0;
    background: #26d48c;
    border-radius: 10px;
    opacity: 1;
    z-index: -1;
    transition: 0.5s;
  }
  
  .project-img::after {
    content: "";
    width: 150px;
    height: 150px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #1842b6;
    border-radius: 10px;
    opacity: 1;
    z-index: -1;
    transition: 0.5s;
  }
  
  .project-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }
  
  .project-content .teacher-info {
    display: inline-block;
    padding: 20px 25px;
    background: #1842b6;
    border-radius: 10px;
  }
  .project-content .teacher-info h4{
     color: white;
  }
  .project-item:hover .project-content {
    opacity: 1;
    transition: 0.5s;
  }
  
  .project-item:hover .project-img::before,
  .project-item:hover .project-img::after {
    opacity: 0;
  }
  .TechnicalSupport3 {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    border-radius: 50%;
    transition: color 0.3s;
  
  
  }
  .TechnicalSupport3 h2{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    line-height: 1em;
    font-size: 70px;
    color: white;
  
  }
  
  .element-counter h4{
    color: #FFFFFF;
    font-size: 30px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    line-height: 1.2em;
    
  }
  .element-counter p{
    color: #FFFFFF;
    font-size: 20px;
  
    font-style: normal;
    font-weight: 400;
    line-height: 1.2em;
  }
  .border-radius{
    border-radius: 20px;
}

.product-item {
    position: relative;
    overflow: hidden;
}

.product-item img {
    transition: transform 0.3s ease;
}

.product-item img:hover {
    transform: scale(1.05);
}
