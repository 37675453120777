

.admission {
   
  
    background: url("../images/history-class.jpg") top center no-repeat;   
    background-color: #FFFFFF;
    width: 100%;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  
}


.admission1 {
   

    background: url("../images/blog-5-1.jpg") top center no-repeat;   
    background-color: #FFFFFF;
    width: 100%;
  
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  
}
.contact-us{
    font-size: 75px;
     font-weight: 700;
     color: rgb(255, 255, 255);
 }
 
 .get-contact{
     font-size: 25px;
     font-style: normal;
     letter-spacing: 1px;
     text-transform: uppercase;
     color: #ffffff;
     margin-top: 15px;
 }
 .TechnicalSupport {
    height: 60px;
    min-width: 60px;
   
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    border-radius: 10px;
    transition: color 0.3s;

}


.TechnicalSupport .fa-3x {
 
    color: #727171a5;
    transition: color 0.3s;
}

.TechnicalSupport:hover .fa-2x {
    color: white;
}
.commen-benefits {
    margin-left: 30px;
  
    justify-content: start;
  
}
.commen-benefits h4{
    color: #666666;
   
    
}
.commen-benefits h6{
    color:  #aaaaaa;
  
    font-weight: 400;
    text-align: inherit;
    font-size: 18px;
    line-height: 30.6px;
    word-spacing: 0px;
    letter-spacing: 0px;

}
.element-common{
    background-color: rgba(19, 18, 18, 0.822);
}
 .team-details {
    background-color: rgba(35, 164, 85, 0.9);
}
.team-details {
    position: absolute;
    top: auto;

   
    left: 0;
    right: 0;
    margin: auto;
   
    padding: 6% 8%;
    opacity: 25;
    -ms-transition: .3s ease-in-out;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    z-index: 10;
}
.abhishek{
    background-color: #f7f7f7;
}

.testimonals-partener h1{
    font-size: 4rem;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #666666

}
.testimonals-partener p{
    color: #666666;
    font-size: 1.5rem;
    font-weight: 400;
    font-style: italic;
    line-height: 1.4em;
}
.border-radius{
    border-radius: 20px;
}

.product-item {
    position: relative;
    overflow: hidden;
}

.product-item img {
    transition: transform 0.3s ease;
}

.product-item img:hover {
    transform: scale(1.05);
}



