.navbar {
    background: #ffffff;
min-height: 50px;
padding-top: 0px;
padding-bottom: 0px;
}

.navbar-name{
    margin-left: 150px;
}
@media (max-width: 1200px) {
   
    .navbar-name{
        margin-left: 0px;
    }
    
      
    }
@media (max-width: 586px) {
   
.navbar-name{
    margin-left: 0px;
}

  
}



.navbar .navbar-nav .nav-link {
    margin-right: 45px;
    padding: 0;
    color: #303972;
    font-weight: 500;
    transition: .5s;
    outline: none;
    font-size:18px;
 
}


  

.navbar .dropdown-toggle::after {
    border: none;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    
    vertical-align: middle;
    margin-left: 8px;
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav {
        padding: 0 15px;

    }

    .navbar .navbar-nav .nav-link {
        margin-right: 0;
        padding: 10px 0;
    }
}

@media (min-width: 992px) {
    .navbar .nav-item .dropdown-menu {
        display: block;
        border: none;
        margin-top: 0;
        top: 150%;
        opacity: 0;
        visibility: hidden;
        transition: .5s;
    }

    .navbar .nav-item:hover .dropdown-menu {
        top: 100%;
        visibility: visible;
        transition: .5s;
        opacity: 1;
    }
}

.navlink {
    text-decoration: none;
    color: inherit;
    padding-bottom: 20px;
    background-color:green;
}



  .collapse .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    border-bottom: 5px solid #61CE70;
    color: #303972;
    font-size: 18px;
    /* background-color:  #FE6500; */

   

}