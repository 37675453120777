.border-radius{
    border-radius: 20px;
}

.product-item {
    position: relative;
    overflow: hidden;
}

.product-item img {
    transition: transform 0.3s ease;
}

.product-item img:hover {
    transform: scale(1.05);
}

