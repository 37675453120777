.footer .btn-link {
    display: block;
    margin-bottom: 20px;
    padding: 0;
    text-align: left;
    color: #303972;
    font-weight: normal;
    transition: .3s;
    text-decoration: none;
}

.footer .btn.btn-link:hover {
    color: #303972;

}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;

}



.footer .copyright a:hover {
    color: #303972;
}

.text-common {
    font-weight: 700;
    color: #303972;
}

.btn-icon {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 50px;
    border-radius: 50px;
    border: 3px solid #303972;
    margin-left: 5px;
    height: 40px;
    width: 40px;

}

.btn-icon:hover {
    background-color: #303972;
}

.btn-outline {
    text-align: center;
    color: #303972;
    font-size: 22px;

}

.btn-outline:hover {
    color: white;
}

.abishek .mb-3 {
    justify-content: center;
    border: none;

}

.abishek .mb-3 .nav-item button {
    background: none;
    border: none;
}

.qrcode {
    display: flex;
    justify-content: center;
}
.footer{
    background: #eeeeee;;
}