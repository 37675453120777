

.contact-bg{
    background: url("../images/contact.bg.b900b16a3bf3db1ee301.webp") top center no-repeat;   
    background-color: #FFFFFF;
    width: 100%;
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  
}


.aboutcontact {
    height: 60px;
    width: 60px;
    border: 2px solid #303972;
    align-items: center;
    display: inline-block;
    border-radius: 50px;
    transition: color 0.3s;
  
  }
  
  .aboutcontact:hover {
    background-color: #303972;
  
  }
  
  .aboutcontact .fa-location-dot {
    margin-top: 12px;
    margin-left: 15px;
    color: #303972;
    transition: color 0.3s;
  }
  
  .aboutcontact:hover .fa-location-dot {
    color: white;
  }
  
  .aboutcontact .fa-envelope {
    margin-top: 12px;
    margin-left: 12px;
    color: #303972;
    transition: color 0.3s;
  }
  
  .aboutcontact:hover .fa-envelope {
    color: white;
  }
  
  
  .aboutcontact .fa-phone {
    margin-top: 12px;
    margin-left: 12px;
    color: #303972;
    transition: color 0.3s;
  }
  
  .aboutcontact:hover .fa-phone {
    color: white;
  }
  .commen-contact {
    display: flex;
  }
  
  .commenleft {
    margin-left: 20px;
  }
  .commenleft h4{
    color: #ec994a;
  }
  .fadeIn  h1{
    color: #0f6060;
  }
  .commen-module-icon {
    font-size: 50px;
  }
  .contact{
    /* background: #cbe7e6;  */
    box-shadow: 42px;
    border-radius: 21px;
    border: 3px solid #ec994a;
  }
  .contact1{
      background: #fafbff;
  padding: 40px; 

   border: 1px solid #e2915e; 
 border-radius: 10px; 
   box-shadow: 10px 20px 30px 40px; 
  box-shadow: 5px 5px 15px 5px;
  }
  
    .form-control:focus {
     color: var(--bs-body-color); 
    background-color: var(--bs-body-bg); 
 border-color: #86b7fe;
     outline: 0; 
    box-shadow: navy; 
   
}




 

